.femanager_captcha-wrapper {

  .femanager_fieldset {
    font-size: 15px;

    .col-sm-10 {
      align-self: baseline;
      margin-top: 5px;
      width: fit-content;

      .tx-srfreecap-image {
        margin-left: 0;
      }
      .form-control {
        display: block;
        margin-top: 5px;
        width: 184px;
      }
    }
  }
}

.tx-srfreecap-cant-read {
  vertical-align: middle;
}
